export const environment = {
  production: false,
  baseUrl: 'https://admin.dev.ecommerce-02.boxooo.com',
  defaultLang: 'en',
  xAuthKey: 'T8MYF3d9LYvUEPGCvQjEcZskB6EMe2rrVuayXCkjZcmefOg4HpdLcbbt28ODsibQ',
  environment: 'development',
  SENTRY_AUTH_TOKEN:
    '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',
  STATSIG_KEY: 'client-lqf47P6CX9v7Uuhhlh7fefpOARJAvS8KVxgZvbaWfEd',
  statsigEnvironment: 'development',
  amplitude: {
    key: '660ce104e1150e78cf36aff6485c920e',
    serverZone: 'US',
  },
  pusher: {
    key: 'aa63761e8d76df6ec9dd',
    cluster: 'eu',
  },
};
